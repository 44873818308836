import type { Checklist } from '../../types'

export const isPublishFirstPostCompleted = (checklist: Checklist): boolean => {
  const firstPostChecklistItem =
    checklist?.items?.find((item) => item.id === 4) || null

  if (!firstPostChecklistItem) {
    return false
  }

  return firstPostChecklistItem.isCompleted === true
}
