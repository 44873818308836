import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import { ProgressBar, Text } from '@bufferapp/ui'

import type {
  Checklist,
  ChecklistProgress,
} from '~publish/legacy/welcome-checklist/types'
import {
  Header,
  ProgressBarWrapper,
  ChecklistTitle,
  DismissButton,
  IconWrapper,
} from './style'
import { useAccount } from '~publish/legacy/accountContext'

import { TrophyIllustration } from '~publish/legacy/welcome-checklist/icons/trophy-illustration'

const ChecklistHeader = ({
  checklist,
  checklistProgress,
  onDismissWelcomeChecklist,
}: {
  checklist: Checklist
  checklistProgress: ChecklistProgress
  onDismissWelcomeChecklist: () => void
}): JSX.Element => {
  const { account } = useAccount()
  const { isEnabled: shouldShowDismissButton } = useSplitEnabled(
    'geid-dismissible-welcome-checklist',
    { email: account.email },
  )

  const shouldShowProgressBar = checklistProgress.progressPercent > 0
  return (
    <Header>
      <ChecklistTitle>
        <Text type="p">{checklist.title}</Text>
        {shouldShowDismissButton ? (
          <DismissButton
            role="button"
            aria-label="Dismiss Welcome Checklist"
            onClick={onDismissWelcomeChecklist}
          >
            <CrossIcon aria-hidden="true" focusable="false" />
          </DismissButton>
        ) : (
          <IconWrapper>
            <TrophyIllustration />
          </IconWrapper>
        )}
      </ChecklistTitle>
      {shouldShowProgressBar && (
        <ProgressBarWrapper>
          <Text type="p">
            {checklistProgress.itemsCompleted} of {checklistProgress.itemsTotal}
          </Text>
          <ProgressBar
            className="checklistProgress"
            progress={`${checklistProgress.progressPercent}%`}
          />
        </ProgressBarWrapper>
      )}
    </Header>
  )
}

export default ChecklistHeader
