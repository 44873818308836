/* eslint-disable @typescript-eslint/naming-convention */
import { type ApolloError, gql, useQuery } from '@apollo/client'
import { useAccount } from '~publish/legacy/accountContext'
import type { Checklist } from '~publish/legacy/welcome-checklist/types'
import { useSplitEnabled } from '@buffer-mono/features'

export const QUERY_CHECKLISTS = gql`
  query checklists($input: OrganizationIdInput!) {
    checklists(input: $input) {
      ... on ChecklistsResponse {
        checklists {
          id
          title
          items {
            id
            description
            isCompleted
          }
        }
      }
      ... on CoreWebAppCommonError {
        code
        error
      }
    }
  }
`

type useChecklistsReturn = {
  checklists: Checklist[]
  loading: boolean
  error: ApolloError | undefined
  refetch: () => void
}

export const useChecklists = (): useChecklistsReturn => {
  const { account } = useAccount()
  const organizationId = account?.currentOrganization?.id

  const { config } = useSplitEnabled<{
    idea_item_title: string
  }>('geid-welcome-checklist-copies')

  const { data, loading, error, refetch } = useQuery(QUERY_CHECKLISTS, {
    variables: { input: { organizationId } },
  })

  let checklists = data?.checklists?.checklists
  if (checklists?.length > 0 && 'idea_item_title' in config) {
    checklists = checklists.map((checklist: Checklist) => {
      const items = checklist.items.map((elem, index) => {
        if (index === 2) {
          return {
            ...elem,
            description: config.idea_item_title,
          }
        }
        return elem
      })
      return { ...checklist, items }
    })
  }

  return { loading, error, checklists, refetch }
}
