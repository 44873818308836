import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BufferTracker } from '@bufferapp/buffer-tracking-browser-ts'
import { useAccount } from '~publish/legacy/accountContext'

import { useSplitEnabled } from '@buffer-mono/features'
import type { Checklist } from '../../types'

import { actions as welcomeChecklistRefetchOperations } from '../../reducer'
import { useChecklists } from '../hooks/useChecklists'
import { selectChecklist } from '../utils/selectChecklist'
import { getChecklistProgress } from '../utils/getChecklistProgress'

import { WelcomeChecklistWrapper } from './style'
import ChecklistHeader from '../ChecklistHeader'
import ChecklistBody from '../ChecklistBody'
import ChecklistFooter from '../ChecklistFooter'
import { hasDismissedObject } from '~publish/legacy/utils/dismissedObjects/hasDismissedObject'
import { isPublishFirstPostCompleted } from '../utils/isPublishFirstPostCompleted'
import useDismissObject from '~publish/legacy/first-post-celebration-modal/components/hooks/useDismissObject'
import { useWelcomeCheckListActions } from '../hooks/useWelcomeChecklistActions'

const COMPLETED_PROGRESS = 100
const CHECKLIST_MODAL_DIMISSED_LOCAL_STORAGE_KEY =
  'isWelcomeChecklistModalDismissed'
const CHECKLIST_COMPLETE_MODAL_DIMISSED_LOCAL_STORAGE_KEY =
  'isChecklistCompleteModalDismissed'
const WELCOME_CHECKLIST_COMPLETE = 'welcome_checklist_complete'
const WELCOME_CHECKLIST_SUPPRESS_MODAL = 'welcome_checklist_suppress_modal'

export const WelcomeChecklist = (): JSX.Element | null => {
  const { account } = useAccount()
  const dispatch = useDispatch()
  const { checklists, loading, error, refetch } = useChecklists()
  const { callWelcomeChecklistAction } = useWelcomeCheckListActions()
  const [dismissedWelcomeChecklist, setDismissedWelcomeChecklist] =
    React.useState(false)

  // Check if we should refetch the checklist status based on app state
  // Check if we should refetch the checklist status based on app state
  const shouldRefetch = useSelector(
    // @ts-expect-error TS(2339) FIXME: Property 'welcomeChecklist' does not exist on type... Remove this comment to see the full error message
    (state) => state.welcomeChecklist.shouldRefetch,
  )

  React.useEffect(() => {
    const dismissed =
      sessionStorage.getItem(CHECKLIST_MODAL_DIMISSED_LOCAL_STORAGE_KEY) ===
      'dismissed'
    setDismissedWelcomeChecklist(dismissed)
  }, [])

  useEffect(() => {
    if (shouldRefetch) {
      refetch()
      dispatch(welcomeChecklistRefetchOperations.clearShouldRefetch())
    }
  }, [dispatch, refetch, shouldRefetch, account])

  let checklist: Checklist | null = null
  let checklistProgress = {
    itemsCompleted: 0,
    itemsTotal: 0,
    progressPercent: 0,
  }
  if (checklists) {
    checklist = selectChecklist(checklists)
    checklistProgress = checklist
      ? getChecklistProgress(checklist)
      : checklistProgress
  }

  const handleChecklistItemClick = (
    id: number,
    checklistItemDescription: string,
    isCompleted: boolean,
  ): void => {
    // Don't track clicks on the first item
    if (id === 1) {
      return
    }
    const eventData = {
      organizationId: account?.currentOrganization?.id || '',
      checklistId: checklist?.id || 0,
      checklistItem: checklistItemDescription || '',
      isCompleted: isCompleted || false,
      qtyItemsCompleted: checklistProgress.itemsCompleted,
    }

    BufferTracker.checklistItemClicked(eventData)
    callWelcomeChecklistAction(id)
  }

  const onDismissWelcomeChecklist = (): void => {
    sessionStorage.setItem(
      CHECKLIST_MODAL_DIMISSED_LOCAL_STORAGE_KEY,
      'dismissed',
    )
    setDismissedWelcomeChecklist(true)
  }

  const dismissedChecklist = hasDismissedObject({
    account,
    objectId: WELCOME_CHECKLIST_COMPLETE,
  })
  const isChecklistCompleteModalDismissed =
    dismissedChecklist ||
    !!localStorage.getItem(CHECKLIST_COMPLETE_MODAL_DIMISSED_LOCAL_STORAGE_KEY)

  // ---------------START EXPERIMENT https://buffer.atlassian.net/browse/GROWTH-58 ---------------
  // Suppress the checklist modal if the following are true:
  // 1) user is in the split group and 2) the Create Your First Post item is the only incomplete item

  const epochsMs = account?.currentOrganization?.createdAt
    ? new Date(account?.currentOrganization?.createdAt).getTime() || 0
    : 0

  const { isEnabled: isStreaksExperimentEnabled } = useSplitEnabled(
    'geid-first-post-celebration',
    {
      signUpDate: epochsMs,
    },
  )
  const isPublishFirstPostItemCompleted = isPublishFirstPostCompleted(
    checklist as Checklist,
  )

  const { onDismissObject } = useDismissObject({
    organizationId: account?.currentOrganization?.id,
    dismissedObject: {
      id: WELCOME_CHECKLIST_SUPPRESS_MODAL,
    },
  })

  // NOTE: After experiment is complete - we can remove the useEffect below, but keep the modalSuppressed value here
  // to ensure that the modal stays suppressed for users who have already auto-dismissed it via this experiment
  const modalSuppressed = hasDismissedObject({
    account,
    objectId: WELCOME_CHECKLIST_SUPPRESS_MODAL,
  })

  const shouldSetModalSuppressedObject =
    isStreaksExperimentEnabled &&
    !isPublishFirstPostItemCompleted &&
    !modalSuppressed

  // If the above criteria are met, dismiss the checklist modal before the user completes the last checklist item
  useEffect(() => {
    if (shouldSetModalSuppressedObject) {
      onDismissObject()
    }
  }, [shouldSetModalSuppressedObject, modalSuppressed, onDismissObject])

  // --------------- END EXPERIMENT ---------------

  const isChecklistComplete =
    checklistProgress.progressPercent === COMPLETED_PROGRESS

  useEffect(() => {
    const { MODALS, actions } = window?.appshell || {}
    if (
      actions &&
      isChecklistComplete &&
      !isChecklistCompleteModalDismissed &&
      !modalSuppressed &&
      !dismissedWelcomeChecklist
    ) {
      // @ts-expect-error TS(2554) FIXME: Expected 2 arguments, but got 1.
      actions.openModal(MODALS.checklistComplete)
    }
  }, [isChecklistComplete, isChecklistCompleteModalDismissed])

  // Hide the checklist if we've seen the modal and the checklist is complete
  const shouldHideChecklist =
    isChecklistCompleteModalDismissed ||
    isChecklistComplete ||
    dismissedWelcomeChecklist

  useEffect(() => {
    if (!shouldHideChecklist && account?.currentOrganization) {
      BufferTracker.welcomeChecklistViewed({
        organizationId: account?.currentOrganization?.id,
        qtyItemsCompleted: checklistProgress.itemsCompleted,
      })
    }
  }, [account, checklistProgress.itemsCompleted, shouldHideChecklist])

  if (shouldHideChecklist || !checklist || !checklists || loading || error) {
    return null
  }

  return (
    <WelcomeChecklistWrapper data-testid="welcome_checklist">
      <ChecklistHeader
        checklist={checklist}
        checklistProgress={checklistProgress}
        onDismissWelcomeChecklist={onDismissWelcomeChecklist}
      />
      <ChecklistBody
        checklist={checklist}
        handleChecklistItemClick={handleChecklistItemClick}
      />
      <ChecklistFooter />
    </WelcomeChecklistWrapper>
  )
}

export default WelcomeChecklist
