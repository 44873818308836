import styled from 'styled-components'
import { white, grayLight } from '@bufferapp/ui/style/colors'

export const WelcomeChecklistWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 224px;

  position: sticky;
  flex-grow: 1;
  flex-shrink: 0;
  bottom: 15px;

  background: ${white};
  border: 1px solid ${grayLight};
  border-radius: 4px;

  box-shadow: 0px 4px 12px 0px #00000021;
`
