import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import LegacyNotifications from '@bufferapp/notifications'
import { AppPages } from '~publish/legacy/app-pages'
import { AppModals } from '~publish/legacy/modals'
import { Notifications } from '~publish/legacy/notifications-provider/components/Notifications'
import TemporaryBanner from '~publish/legacy/temporary-banner'
import ThirdParty from '~publish/legacy/thirdParty'
import LoadingGate from '../LoadingGate'
import { WelcomeChecklistContainer } from '~publish/legacy/welcome-checklist/components/WelcomeChecklistContainer'
import { StreaksContainer } from '~publish/legacy/streaks/components/StreaksContainer'
import ComposerPopover from '~publish/legacy/composer-popover'
import { OptedOutBanner } from '~publish/legacy/date-time-preferences/components/NewPublishExperience/OptedOutBanner'

const ThirdPartyWithRouter = withRouter(ThirdParty)
const AppPagesWithRouter = withRouter(AppPages as any)

const appStyle = {
  display: 'flex',
  height: '100%',
}

const contentStyle = {
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column' as const,
  width: '100vw',
  background: '#fff',
  // TODO: we should refactor layout styles here
  // so thet there is ability to use full page scroll
  overflow: 'hidden',
}

// Can't use stateless function for App since then
// the `DragDropContext` doesn't work.
// eslint-disable-next-line
class App extends Component {
  render(): JSX.Element {
    return (
      <div style={appStyle} className="notranslate app-root">
        <div style={contentStyle}>
          <LoadingGate>
            <OptedOutBanner />
            <TemporaryBanner />
            <ComposerPopover />
            <AppPagesWithRouter />
            <WelcomeChecklistContainer />
            <StreaksContainer />
          </LoadingGate>
        </div>
        <Notifications />
        <LegacyNotifications />
        <AppModals />
        <ThirdPartyWithRouter />
      </div>
    )
  }
}

export default DragDropContext(HTML5Backend)(App)
