import React, { useContext, useEffect } from 'react'
import { AccountContext } from '~publish/legacy/accountContext'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { hasDismissedObject } from '~publish/legacy/utils/dismissedObjects/hasDismissedObject'
import { FIRST_POST_CELEBRATION_MODAL } from '~publish/legacy/first-post-celebration-modal/components/FirstPostCelebrationModal'
import { useSplitEnabled } from '@buffer-mono/features'

// This component is initially used as a container for Growth Experiemnts related to posting streaks
export const StreaksContainer = (): JSX.Element => {
  const { account, refetchAccount } = useContext(AccountContext)

  const epochsMs = account?.currentOrganization?.createdAt
    ? new Date(account?.currentOrganization?.createdAt).getTime() || 0
    : 0

  const { isEnabled: isStreaksExperimentEnabled } = useSplitEnabled(
    'geid-first-post-celebration',
    {
      signUpDate: epochsMs,
    },
  )

  const dispatch = useAppDispatch()

  const hasDismissedModal = hasDismissedObject({
    account,
    objectId: FIRST_POST_CELEBRATION_MODAL,
  })

  const shouldTriggerModal = useAppSelector(
    (state) => state.streaksContainer.shouldTriggerModal,
  )

  useEffect(() => {
    if (
      isStreaksExperimentEnabled === true &&
      shouldTriggerModal === true &&
      refetchAccount
    ) {
      refetchAccount()
    }
  }, [refetchAccount, shouldTriggerModal, isStreaksExperimentEnabled])

  if (
    isStreaksExperimentEnabled === true &&
    shouldTriggerModal &&
    hasDismissedModal === false
  ) {
    // Wait a second for the composer to close before dispatching the modal event
    setTimeout(() => {
      dispatch(modalActions.showFirstPostCelebrationModal())
    }, 1000)
  }

  return <></>
}
