import React from 'react'

export const TrophyIllustration = () => {
  return (
    <svg
      width="64"
      height="54"
      viewBox="0 0 64 54"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.5 40C34.2585 42.1732 35.6151 46.2178 41.6999 46.2178V52.7372H23.5903V46.58C26.1256 46.8214 31.0795 47.0053 30.5 41.5M19.5243 34.5933C19.5243 34.5933 21.3353 33.5067 20.9731 31.6958C20.6109 29.8848 13.7293 26.2629 13.3671 23.7276C13.0049 21.1922 15.178 20.1056 16.989 20.4678C18.8 20.83 19.1621 22.641 19.1621 23.0032M45 34.5933C45 34.5933 43.189 33.5067 43.5512 31.6958C43.9134 29.8848 50.7951 26.2629 51.1573 23.7275C51.5194 21.1922 49.3463 20.1056 47.5353 20.4678C45.7244 20.83 45.7244 22.641 45.7244 23.0032"
        stroke="#313131"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.2772 38.2421C41.8688 37.8894 43.4516 25.0436 42.8635 18.6196C36.0884 16.6573 26.396 17.8019 22.3967 18.6196C21.8085 25.0436 22.7494 38.5924 32.2772 38.2421Z"
        fill="url(#paint0_linear_641_48318)"
        stroke="#F1C108"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1689 38.7723L10 37.6654L10.6427 35L11.8117 36.1069L11.1689 38.7723Z"
        fill="#EFC905"
      />
      <path
        d="M26.0547 10.6351L24 8.68823L25.1298 4L27.1845 5.94689L26.0547 10.6351Z"
        fill="#F1C108"
      />
      <path
        d="M4.98815 24.8288C4.96168 25.587 4.35102 26.1821 3.6254 26.1568C2.89977 26.1314 2.33211 25.4952 2.35859 24.737C2.38507 23.9787 2.99572 23.3836 3.72135 23.4089C4.44698 23.4343 5.01463 24.0705 4.98815 24.8288Z"
        fill="#2AFF28"
      />
      <path
        d="M55.4559 3.19963C55.4294 3.9579 54.8188 4.55298 54.0932 4.52764C53.3675 4.5023 52.7999 3.86608 52.8264 3.10781C52.8528 2.34954 53.4635 1.75446 54.1891 1.7798C54.9148 1.80514 55.4824 2.44137 55.4559 3.19963Z"
        stroke="#3D3D3D"
        strokeWidth="1.03473"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <g opacity="0.683511">
        <path
          d="M59.8322 21.3357C59.1554 22.5081 58.1549 23.1996 57.5996 22.879C57.0442 22.5583 57.1429 21.3462 57.8197 20.1738C58.4966 19.0015 59.497 18.31 60.0523 18.6306C60.6077 18.9513 60.509 20.1634 59.8322 21.3357Z"
          fill="#EF0583"
        />
      </g>
      <path
        d="M42.2637 8.92914L38 9.2249L40.582 5L42.2637 8.92914Z"
        fill="#2B4CFE"
      />
      <path
        d="M56.6453 42.0404L61.4768 41.5446L62.543 36.8766L57.8588 37.3899L56.6453 42.0404Z"
        fill="#7BB800"
      />
      <path
        d="M11.5798 6L14.7485 9.16873"
        stroke="#1E1E1E"
        strokeWidth="1.03473"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_641_48318"
          x1="32.6151"
          y1="17.5361"
          x2="32.6151"
          y2="38.2487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE750" />
          <stop offset="1" stopColor="#FBC400" />
        </linearGradient>
      </defs>
    </svg>
  )
}
