import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import * as Tooltip from '@radix-ui/react-tooltip'
import { ApolloProvider, useQuery } from '@apollo/client'
import { store, history } from '~publish/legacy/store'
import { FeaturesWrapper } from '@buffer-mono/features'
import { PopcornProvider } from '@buffer-mono/popcorn'

import { PusherContextProvider } from '~publish/services/pusher'
import { ThemeProvider } from 'styled-components'
import { theme } from '~publish/legacy/theme'
import getErrorBoundary from '../../components/ErrorBoundary'
import { client } from '../../../apollo-client'
import { AccountContext, GET_ACCOUNT } from '../../../accountContext'
import GlobalStyles from '~publish/legacy/global-styles/globalStyles'
import '~publish/legacy/composer/composer/components/css/constants.css'
import { env } from '~publish/env'

import '../../components/i18n'

import App from '../App'

function getQueryParams(): URLSearchParams {
  const url = new URL(window.location.href)
  const params = new URLSearchParams(url.search)
  return params
}

window.VERSION =
  env.VITE_NODE_ENV === 'development' ? 'local' : env.VITE_COMMIT_HASH

const ErrorBoundary = getErrorBoundary()

const Publish = (): JSX.Element => {
  const {
    loading,
    data: account,
    refetch: refetchAccount,
  } = useQuery(GET_ACCOUNT)

  const { ORGANIZATION_EVENT_KEY } = window?.appshell?.eventKeys || {}
  const { billingUpdated } = window?.appshell?.actionKeys || {}

  useEffect(() => {
    store.dispatch({ type: 'APP_INIT', queryParams: getQueryParams() })
  }, [])

  useEffect(() => {
    function onOrgChange({ detail }: { detail: { action: string } }): void {
      if (!detail.action) {
        refetchAccount()
      } else if (detail.action === billingUpdated) {
        refetchAccount()
        store.dispatch({ type: 'INIT_ORGANIZATIONS' })
      }
    }
    window.addEventListener(ORGANIZATION_EVENT_KEY, onOrgChange)

    return function cleanup(): void {
      window.removeEventListener(ORGANIZATION_EVENT_KEY, onOrgChange)
    }
  }, [refetchAccount, ORGANIZATION_EVENT_KEY, billingUpdated])

  return (
    <ErrorBoundary>
      <FeaturesWrapper environment={env.VITE_SPLIT_ENV}>
        <AccountContext.Provider
          value={{ ...account, loading, refetchAccount }}
        >
          <Provider store={store}>
            <Router history={history}>
              <React.Suspense fallback={<div>Loading...</div>}>
                <PusherContextProvider>
                  <PopcornProvider
                    theme="light"
                    portalContainerId="portals-container"
                  >
                    <ThemeProvider theme={theme}>
                      <Tooltip.Provider>
                        <App />
                      </Tooltip.Provider>
                    </ThemeProvider>
                  </PopcornProvider>
                </PusherContextProvider>
              </React.Suspense>
            </Router>
          </Provider>
        </AccountContext.Provider>
      </FeaturesWrapper>
    </ErrorBoundary>
  )
}

export function PublishRoot(): JSX.Element {
  return (
    <ApolloProvider client={client}>
      <GlobalStyles />
      <Publish />
    </ApolloProvider>
  )
}
